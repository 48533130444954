<template>
    <form @submit.prevent>
        <b-form-group>
            <b-input-group>
                <b-input-group-prepend slot="prepend">
                    <b-btn variant="secondary" class="round-left" disabled>
                        <font-awesome-icon icon="search"/>
                    </b-btn>
                </b-input-group-prepend>
                <b-input type="text" name="search"
                         placeholder="Rechercher"
                         class="round-right pl-3"
                         v-model="searchText"
                         @keyup="search"/>
            </b-input-group>
        </b-form-group>
    </form>
</template>

<script>
    import Fuse from 'fuse.js';

    export default {
        name: "SearchBar",
        props: {
            objects: {
                type: Array,
                required: true
            },
            fuseOptions: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            defaultFuseOptions: {
                shouldSort: true,
                keys: ['nom']
            },
            fuse: null,
            searchText: ''
        }),
        methods: {
            search() {
                this.$emit('filter', this.searchText ? this.fuse.search(this.searchText) : this.objects);
            }
        },
        mounted() {
            this.fuse = new Fuse(
                this.objects,
                {...this.defaultFuseOptions, ...this.fuseOptions}
            );
        }
    }
</script>

<style scoped>
    .round-left {
        border-bottom-left-radius: 2rem;
        border-top-left-radius: 2rem;
    }

    .round-right {
        border-bottom-right-radius: 2rem;
        border-top-right-radius: 2rem;
    }

    button.round-left {
        border-color: #cad1d7;
    }

    button.round-left:hover {
        box-shadow: none;
    }
</style>